
import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import signupback from '../images/signupback.jpg'



const Terms = () => (
  <Layout>
   <div class="inner-page">
   <div class="slider-item overlay bg-overly " style={{backgroundImage:`url(${signupback})`}} >
    {/* <div class="slider-item " style="background-image: url('');"> */}
        <div class="container">
        <div class="flex flex-row slider-text items-center justify-center" style={{height:450}}>
        {/* <div class="flex-col items-center justify-center text-center col-sm-12 element-animate"> */}
          <h1 class="self-center text-white text-5xl">TERMS OF SERVICE</h1>
        {/* </div> */}
      </div>
            
        </div>
    </div>
</div>
<div class="flex flex-row mx-4 md:px-8 md:mx-8">
    <div class="flex flex-col mx-auto my-5">
            <div>
                <h6>
                    Using the website
                </h6>
                <p>
                    This terms of usage is linked to the website located at <a>www.healthpeer.ng</a> and the health peer
                    app which is herein referred to in this Terms of Use collectively as “the Site”. The term ‘You or
                    Member’ refers to the user or viewer of our website who register to use our e-health services to
                    report their health issues and engage the service of verified healthcare Professionals on our Site.
                </p>
                <p>
                    Please read these terms carefully so that you understand them. These terms cover all the services we
                    provide to you through our healthcare Site. The term ‘Healthpeer or Healthpeer.ng’ or ‘us’ or ‘we’
                    refers to the owner of the Site whose registered office is [address].If you continue to browse and
                    use the Site, you are agreeing to comply with and be bound by the following Terms and Conditions of
                    use, which together with our Privacy Policy govern health peer’s relationship with you in relation
                    to this Site. If you disagree with any part of these terms and conditions, please do not use our
                    Site.
                </p>
            </div>
            <div>
                <h6>
                    Health Care Service
                </h6>
                <p>
                    The Healthcare Professionals who deliver Services through our Site are independent Professionals
                    practicing within a group of independently owned professional practices collectively known as
                    “Healthpeer”. We do not practice medicine or any other licensed Profession and does not interfere
                    with the practice of medicine or any other licensed profession by Healthcare Professionals, each of
                    whom is responsible for his or her services and compliance with the requirements applicable to his
                    or her profession and license. Neither Healthpeer nor any third parties who promote the Services or
                    provide you with a link to the Services shall be liable for any Professional advice you obtain from
                    a Healthcare Professional via the Services.
                </p>
            </div>
            <div>
                <h6>
                    Site Content
                </h6>
                <p>
                    None of the Site content (other than information you receive from Healthcare Professionals) should
                    be considered medical advice or an endorsement, representation or warranty that any medication or
                    treatment is safe, appropriate, or effective for you.
                </p>
                <p>The use of the Site is subject to the following terms of use:</p>
                <ul>
                    <li>Some conditions are not suitable for remote advice and we recommend that you check our FAQs
                        prior to use of the services which may help you in assessing their suitability in relation to
                        your symptoms.</li>
                    <li>Health and Medical advices are provided on the Site through Video and Audio Consultations.</li>
                    <li>The use of this Site does not cover medical Emergencies</li>
                    <li>Unrestricted/Unsupervised use by persons below the age of 13 is not allowed.</li>
                    <li>The content of the pages of this website is for your general information and use only. It is
                        subject to change without notice.</li>
                    <li>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used,
                        the following personal information may be stored by us for use by third parties:
                        [Name,Age,Sex,Address].</li>
                    <li>This website contains material which is owned by or licensed to us. This material includes, but
                        is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited
                        other than in accordance with the copyright notice, which forms part of these terms and
                        conditions.</li>
                    <li>All trademarks reproduced in this website, which are not the property of, or licensed to the
                        operator, are acknowledged on the website.</li>
                    <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal
                        offence.</li>
                    <li>From time to time, this website may also include links to other websites. These links are
                        provided for your convenience to provide further information. They do not signify that we
                        endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                    <li>Your use of this website and any dispute arising out of such use of the website is subject to
                        the laws of Nigeria.</li>
                </ul>
            </div>
            <div>
                <h6>
                    Privacy
                </h6>
                <p>
                    As required by law, Health Peer must comply with federal healthcare privacy and security laws and
                    maintain safeguards to protect the security of your health information. The information you supplied
                    to the Healthcare Professional during a medical consultation or therapy session is treated as a
                    legally confidential information, excluding certain cases permitted by law as fully covered in our
                    Privacy Policy. Considerable effort is geared toward protecting your personal information.
                    Information regarding our use of health and other personal information is provided in our Privacy
                    Policy. As part of providing you the Services, we may need to provide you with certain
                    communications, such as appointment reminders, service announcements and administrative messages.
                    These communications are considered part of the Services and your Account. While secure electronic
                    messaging is always preferred to insecure email, under certain circumstances, insecure email
                    communication containing personal health information may take place between you and Health Peer. We
                    however, cannot ensure the security or confidentiality of messages sent by email. Information
                    relating to your care, including clinical notes and medical records, are stored on secure, encrypted
                    servers maintained by us.
                </p>
            </div>
            <div>
                <h6>
                    Client Account
                </h6>
                <p>
                    When you register on the Site, you are required to create an account (“Account”) by entering your
                    name, email address, password and certain other information collected by Healthpeer (collectively
                    “Account Information”). To create an Account, you must be of legal age to form a binding contract.
                    If you are not of legal age, you may not register to use our Services. You agree that the Account
                    Information that you provide to us at all times, including during registration and in any
                    information you upload to the Site, will be true, accurate, current, and complete. You may not
                    transfer or share your Account password with anyone or create more than one Account (except for
                    subaccounts established for children of whom you are the parent or legal guardian). You are
                    responsible for maintaining the confidentiality of your Account password and for all activities that
                    occur under your Account. HealthPeer reserves the right to take all action, as it deems necessary or
                    reasonable, regarding the security of the Site and your Account Information. In no event and under
                    no circumstances shall Healthpeer be held liable to you for any liabilities or damages resulting
                    from or arising out of your use of the Site, your use of the Account Information or your release of
                    the Account Information to a third party. You may not use anyone else's account at any time.
                </p>
            </div>
            <div>
                <h6>
                    Access to Service by Children
                </h6>
                <p>
                    Health peer services can be accessed by children under the age of 18 on the condition that the usage
                    be supervised by the patient’s parent or legal guardian. You are however required to fully comply
                    with the Terms of Use in this case.
                </p>
            </div>
            <div>
                <h6>
                    Your Right
                </h6>
                <p>
                    You are granted a limited, non-exclusive, non-transferable right to access the Site and use the
                    Services solely for your personal non-commercial use and only as permitted under these Terms of Use
                    and any separate agreements you may have entered with us (<b>“Your Right”</b>). We reserve the
                    right, in our sole discretion, to deny or suspend use of the Site or Services to anyone for any
                    reason. You agree that you will not, and will not attempt to: (a) impersonate any person or entity,
                    or otherwise misrepresent your affiliation with a person or entity; (b) use the Site or Services to
                    violate any local, state, national or international law; (c) reverse engineer, disassemble,
                    decompile, or translate any software or other components of the Site or Services; (d) distribute
                    viruses or other harmful computer code through the Site; or (e) otherwise use the Services or Site
                    in any manner that exceeds the scope of use granted above. In addition, you agree to refrain from
                    abusive language and behaviour which could be regarded as inappropriate, or conduct that is unlawful
                    or illegal, when communicating with Healthcare Professionals through the Site and to refrain from
                    contacting Healthcare Professionals for telehealth services outside of the Site. Health Peer is not
                    responsible for any interactions with Healthcare Professionals that are not conducted through the
                    Site. We strongly recommend that you do not use the Services on public computers. We also recommend
                    that you do not store your Account password through your web browser or other software.
                </p>
            </div>
            <div>
                <h6>
                    Service Fees and Terms
                </h6>
                <p>
                    You agree to pay all fees or charges to your Account in accordance with the fees, charges, and
                    billing terms in effect at the time a fee or charge is due and payable. By providing Healthpeer with
                    your debit card details and associated payment information, you agree that Healthpeer is authorized
                    to immediately invoice your account for all fees and charges due and payable to HealthPeer hereunder
                    and that no additional notice or consent is required. If the fee is pursuant to some other
                    arrangement with HealthPeer, that fee adjustment will be reflected in the fee that you are
                    ultimately charged.
                </p>
                <p>
                    HealthPeer reserves the right to change or adopt a new pricing model at any time prior to billing
                    you for your initial payment or for future payments due pursuant to these Terms of Use. You
                    understand and agree that for Services provided on an appointment basis, you will be responsible for
                    a missed appointment fee equal to all or a portion of the fees you would have paid for the scheduled
                    services if you do not cancel a scheduled appointment at least 24 hours in advance, unless we notify
                    you in writing that a shorter cancellation window applies.
                </p>
            </div>
            <div>
                <h6>
                    Ownership
                </h6>
                <p>
                    The Site and its entire contents, features and functionality (including but not limited to all
                    information, software, text, displays, images, video and audio, and the design, selection and
                    arrangement thereof), are owned by Health Peer , its licensors or other providers of such material
                    and are protected by the Nigeria and international copyright, trademark, patent, trade secret and
                    other intellectual property or proprietary rights laws. These Terms of Use permit you to use the
                    Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create
                    derivative works of, publicly display, publicly perform, republish, download, store or transmit any
                    of the material on our Site except as generally and ordinarily permitted through the Site according
                    to these Terms of Use. You must not access or use for any commercial purposes any part of the Site
                    or any services or materials available through the Site.
                </p>
            </div>
            <div>
                <h6>
                    TradeMarks
                </h6>
                <p>
                    Certain names, logos, and other materials displayed on the Site or in the Services may constitute
                    trademarks, trade names, service marks or logos ("Marks") of HealthPeer or other entities. You are
                    not authorized to use any such Marks without the express written permission of Health Peer.
                    Ownership of all such Marks and the goodwill associated therewith remains with us or those other
                    entities.
                </p>
            </div>
            <div>
                <h6>
                    Modification
                </h6>
                <p>
                    We may at our sole discretion change, add, or delete portions of these Terms of Use at any time on a
                    going-forward basis. Continued use of the Site and/or Services following notice of any such changes
                    will indicate your acknowledgement of such changes and agreement to be bound by the revised Terms of
                    Use, inclusive of such changes.
                </p>
            </div>
            <div>
                <h6>
                    Termination
                </h6>
                <p>
                    You have the right to deactivate your account at any time by sending a mail to
                    <a>info@healthpeer.ng</a>. Healthpeer may suspend or terminate your use of the Site, your Account
                    and/or registration for any reason at any time. Subject to applicable law, Healthpeer reserves the
                    right to maintain, delete or destroy all communications and materials posted or uploaded to the Site
                    pursuant to its internal record retention and/or content destruction policies. After such
                    termination, Healthpeer will have no further obligation to provide the Services, except to the
                    extent we are obligated to provide you access to your health records or Healthcare Professionals are
                    required to provide you with continuing care under their applicable legal, ethical and professional
                    obligations to you.
                </p>
            </div>
            <div>
                <h6>
                    Geographical Limitation
                </h6>
                <p>
                    Health Peer do not portray that all products, services and materials described or presented on the
                    Site or Services available through the Site are available or fit for use in areas outside of the
                    Federal Republic of Nigeria.
                </p>
            </div>
            <div>
                <h6>
                    Declaration
                </h6>
                <p>
                    All Health Professionals on the Site hold professional licenses issued by the Medical and Dental
                    Council of Nigeria. Health Peer have also carried out background checks to ensure the Professionals
                    have unquestionable record. You can report a complaint relating to the care provided by a Healthcare
                    Professional by sending a mail to <a>info@healthpeer.ng</a> with evidence or evidences to back up your
                    claim. We will thereafter act by reporting to the Council and appropriate action will be carried
                    out. In a professional relationship, sexual intimacy is never appropriate and should be reported.
                </p>
                <p>
                    You can find the contact information for each of the Professional providing service to you at the
                    Country’s regulatory body at <a href="https://www.mdcn.gov.ng/">https://www.mdcn.gov.ng/</a> under
                    the link (“Confirm a Doctor”).
                </p>
                <p>
                    Any clinical records created as a result of your use of the Services will be securely maintained by
                    Health Peer on behalf Health Peer Professionals for a period that is no less than the minimum number
                    of years such records are required to be maintained by the law.
                </p>
            </div>
           

    </div>
</div>
    
  </Layout>
)

export default Terms




